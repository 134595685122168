'use client';

import Script from 'next/script';
import { useEffect } from 'react';

import { useConsent } from '@lichtblick/consent';

export const HubSpotTrackingScript = () => {
  const linkedinConsent = useConsent('LinkedIn Ads');
  const facebookConsent = useConsent('Facebook');
  const googleAdsConsent = useConsent('Google Ads');
  const googleCustomerMatchConsent = useConsent('Google Customer Match');

  // check if there is a `hubspotutk` cookie set to identify the user in
  // HubSpot and send the consent status for Meta, Google and Linkedin
  useEffect(() => {
    const hubspotUserTokenExists = document?.cookie?.includes?.('hubspotutk');

    if (hubspotUserTokenExists) {
      const hubspotUserToken = document.cookie
        .split(';')
        .find((cookie) => cookie.trim().startsWith('hubspotutk'))
        ?.split('=')[1];

      const _hsq = (window as any)._hsq || [];

      _hsq.push([
        'identify',
        {
          hutk: hubspotUserToken,
          consent_status_meta: facebookConsent,
          consent_status_linkedin: linkedinConsent,
          consent_status_google_ads: googleAdsConsent,
          consent_status_google_customer_match: googleCustomerMatchConsent,
        },
      ]);

      _hsq.push(['trackPageView']);
    }
  }, [linkedinConsent, facebookConsent, googleAdsConsent, googleCustomerMatchConsent]);

  return (
    <Script
      async
      data-usercentrics="HubSpot"
      defer
      id="hs-script-loader"
      src="//js-eu1.hs-scripts.com/143379264.js"
      type="text/plain"
    />
  );
};
